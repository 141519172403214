import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostCardCreator from "../components/postCardCreator"
import PostCardCreatorFeatured from "../components/postCardCreatorFeatured"

// import "../utils/global.scss"
import "../utils/normalize.css"
import "../utils/css/screen.css"
//TODO: switch to staticQuery, get rid of comments, remove unnecessary components, export as draft template
const CreatorsCat = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title
  const postsCreators = data.allContentfulAllContentTypes.edges
  const postsCreatorsFeatured = data.allContentfulAllContentTypes.edges
  const categorias = data.allContentfulContentType.edges
  let postCounter = 0

  return (
    <Layout title={siteTitle}>
      <SEO
        title="All posts"
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />
      
        
      <div className="row" style={{backgroundColor: "#fff"}}>
        <div className="col-12 box-sections">
        <div className="post-content" 
            style={{
              paddingTop: "50px", 
              paddingBottom: "50px",
              textAlign: "center", backgroundImage: "radial-gradient(#79FE0C 5%,#79FE0C 15%, white 60%)"
            }}
           >
            <h1 className="post-content-header" style={{marginTop: "150px", height: "200px",}}>Criadors</h1>
          </div>
          <div className="post-feed">
            {postsCreators.map(({ node }) => {
              postCounter++
              return (
                  <PostCardCreator
                    key={node.slug}
                    count={postCounter}
                    node={node}
                    postClass={`post`}
                  />
                  )
                }
              )
            }
          </div>
        </div>
      </div>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allContentfulAllContentTypes(filter: {category: {eq: "Conteúdos Híbridos"}}, sort: {fields: publishedDate, order: ASC}){
      edges{
        node{
          title
          description
          publishedDate
          slug
          image{
            fluid(maxWidth: 2048, quality: 100){
              src
            }
          }
        }
      }
    }
    allContentfulContentType {
    edges {
      node {
        name
        displayField
        }
      }
    }
    
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <CreatorsCat location={props.location} props data={data} {...props} />
    )}
  />
)
